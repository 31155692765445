* {
  padding: 0;
  margin: 0;
}

.container {
  margin: 10px;
  padding: 5px;
}

.content-list-table {
  color: #fff !important;
  font-weight: bold;
}

.content-list-table-printing {
  color: rgb(8, 8, 24) !important;
  font-weight: bold;
}

.login-icon {
  position: relative !important;
  left: 40%;
}

.content-comm-item {
  background-color: #2d30921c;
  border: 2px solid #2d3192;
}

.content-status {
  width: 100%;
  height: 100%;
  border: 1px solid #a8abf598;
  display: flex;
  border-radius: 3px;
}
.content-plus {
  height: 100%;
  display: flex;
  border-radius: 3px;
  border: 1px solid #a8abf598;
  color: #fff !important;
  background-color: #2d3192;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content-plus-icon {
  font-size: 30px !important;
}

.content-dispatch-company {
  margin: 10px !important;
  padding: 7px !important;
  border-radius: 3px !important;
  border: 1px solid #00000059 !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.content-loader {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  margin-top: 350px;
}

/* commodity-btn Desing */

@media screen and (max-width: 700px) {
  .mobile_contaien {
    width: 100px;
    height: 10vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .logo {
    position: relative !important;
    right: 10%;
  }
  .content-plus-icon {
    font-size: 25px !important;
  }
}

@media screen and (max-width: 300px) {
  .logo {
    position: relative !important;
    right: 10%;
  }

  .content_center {
    width: 150px !important;
  }
}

@media all {
  .page-break {
    display: none;
  }
}

@media print {
  html,
  body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }
}

@media print {
  #non-printable {
    display: none;
  }
  #printable {
    display: flex;
  }
  .page-break {
    margin-top: 0rem;
    display: block;
    page-break-before: auto;
  }

  html,
  body {
    margin: 0;
    padding: 0;
    background: #e5e5e5;
    font-size: 10pt;
    flex: auto;
  }
  header,
  footer,
  aside,
  form {
    display: none;
  }
  article {
    width: 100% !important;
    padding: 0 !important;
    margin: 0 !important;
  }
}

@page {
  size: auto;
  margin: 20mm;
}
